import * as React from "react"
import { Link } from "gatsby"

import Contact from "../components/contact"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as button from "../styles/buttons.module.scss"
import * as styles from "../styles/introduction.module.scss"

const TrainingForGroups = () => {
  return (
    <Layout>
      <Seo title="Bemutatkozás" />

      <div id={styles.container}>
        <section>
          <header id={styles.bar1}>
            <h1>Ismerkedj meg velem</h1>
          </header>
        </section>

        <section id={styles.bar2}>
          <div id={styles.container}>
            <div id={styles.text}>
              <h2>Személyi és aerobic edző </h2>

              <p>
                Edzéseimet a dinamizmus, a változatosság, a precizitás és jókedv
                jellemzi. Ne várj tőlem könnyű edzéseket, mert ahhoz, hogy
                fejlődj vagy változz sajnos keményen meg kell küzdened! <br />
                <b>
                  Nem hiszek a csodákban, a hirtelen változásokban, viszont
                  hiszek a kitartó munkában.
                </b>{" "}
                Én biztosítom hozzá a módszereket, az eszközöket, de neked kell
                hozzáadni az energiát, a lelkesedést, a változtatni akarást!
                Nélküled nem megy... <br />
                Csoportos valamint személyi edzéseimen is erre számíthatsz, mert
                csak ez hozza meg a várt eredményt. Előbb-utóbb tapasztalni
                fogod a változást; jobban fogod érezni magad, könnyebben mennek
                majd a gyakorlatok, erősödni, fejlődni fogsz.
              </p>

              <div id={styles.buttonContainer}>
                <Link
                  className={`${button.buttons} ${button.buttonLink2}`}
                  to="/calendar/"
                >
                  Foglalj időpontot
                </Link>
              </div>
            </div>

            <div id={styles.picture}>
              <div id={styles.image}></div>
            </div>

            <div id={styles.bottomText}>
              <h2>Táplálkozási tanácsadó</h2>

              <p>
                3 éve foglalkozom táplálkozási tanácsadássalal és családi
                vállalkozásban egy egészséges élelmiszereket kínáló webáruházat
                is üzemeltetünk. Nem könnyű kiigazodni a különféle diéta
                módszerek között, már csak ezért sem, mert sokat közülük csak a
                divat, a profitszerzés hoz létre. <br />
                Valójában ma már a tudomány - és itt a sporttáplálkozás az
                élvonalban jár -, nagyon is jól tudja, hogy hogyan kell
                egészségesen táplálkozni. Ez nem is annyira bonyolult dolog,
                mint sokan gondolják.
                <b>
                  {" "}
                  Néhány alapvető szabályt kell betartani: mikor, hányszor és
                  milyen összetételben, mennyit együnk és igyunk. Mindezt attól
                  függően, hogy milyen korban vagyunk, mennyire vagyunk
                  fizikailag aktívak és milyen az egészségi állapotunk.
                </b>
              </p>
            </div>

            <div id={styles.veryBottomText}>
              <h2>Szakmai hátterem</h2>

              <ul>
                <li>Aerobik sportedző (Testnevelési Egyetem)</li>
                <li>Dance-step oktató (Fitness Company) </li>
                <li>Drilling oktató (Fitness Company)</li>
                <li>TRX Suspensioon tréner (R-Med Akadémia) </li>
                <li>Funkcionális tréner (BOSU Hungary)</li>
                <li>Gymstick Muscle tréner (Gymstick Akadémia)</li>
                <li>Stability Workout instruktor (Gaál Attila) </li>
                <li>Gyermekfitnesz és gyermekaerobik oktató (IWI)</li>
                <li>Életmód és táplálkozási tanácsadó (Weider) </li>
                <li>
                  Sporttáplálkozás és táplálék-kiegészítés tanácsadó (Weider)
                </li>
                <li>
                  SMR (Self myofascial release) a sport és mozgásszervi
                  rehabilitációban ( Feövenyessy Akadémia)
                </li>
                <li>
                  BOSU Kids instabil közeg és proprioceptív fejlesztés a
                  gyerekkorban ( BOSU Hungary)
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* <section>
          <div id={styles.bar3}>
            <div id={styles.video}>YOUR VIDEO</div>
          </div>
        </section> */}

        <Contact />
      </div>
    </Layout>
  )
}

export default TrainingForGroups
